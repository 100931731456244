<template>
  <div class="service">
    <div>
      <div class="service-title">组织培训</div>
    </div>
    <!-- <template v-if="userInfo.areaCode == 511703">
      <div class="dachuan_content">
        <div class="content_left">
          <div class="content_item">
            <p class="spcol">98</p>
            <p>累计培训场次</p>
          </div>
          <div class="content_item">
            <p>152</p>
            <p>社工培训人数</p>
          </div>
          <div class="content_item">
            <p>633</p>
            <p>志愿者培训人数</p>
          </div>
          <div class="content_item">
            <p class="spcol">785</p>
            <p>累计培训人数</p>
          </div>
          <div class="content_item">
            <p>65</p>
            <p>社工组织数</p>
          </div>
          <div class="content_item">
            <p>187</p>
            <p>志愿者队伍</p>
          </div>
        </div>
      </div>
    </template> -->

    <template>
      <div class="common-content">
        <div class="common_left">
          <div class="common_item">
            <p class="spcol">
              {{ insmes.institutionInfo.trainingInfo.trainingTotalCount }}
            </p>
            <p>累计培训人数</p>
          </div>
          <div class="common_item">
            <p>{{ insmes.institutionInfo.trainingInfo.orgTrainingCount }}</p>
            <p>社工组织(培训人数)</p>
          </div>
          <div class="common_item">
            <p>{{ insmes.institutionInfo.trainingInfo.volunteerCount }}</p>
            <p>志愿者(培训人数)</p>
          </div>
          <div class="common_item">
            <p class="spcol">
              {{ insmes.institutionInfo.trainingInfo.trainingTotalTimes }}
            </p>
            <p>累计培训人次</p>
          </div>
          <div class="common_item">
            <p>{{ insmes.institutionInfo.trainingInfo.orgTrainingTimes }}</p>

            <p>社工组织(培训人次)</p>
          </div>
          <div class="common_item">
            <p>{{ insmes.institutionInfo.trainingInfo.volunteerTimes }}</p>
            <p>志愿者(培训人次)</p>
          </div>
        </div>
        <div class="common_right">
          <div class="common_item">
            <p class="spcol">
              {{ insmes.institutionInfo.trainingInfo.orgPeopleTotalCount }}
            </p>
            <p>社工组织总人数</p>
          </div>
          <div class="common_item">
            <p class="spcol">
              {{ insmes.institutionInfo.trainingInfo.volunteerTotalCount }}
            </p>
            <p>志愿者总人数</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { getStatistics } from '@/api/homePage/right.js'

export default {
  data() {
    return {
      insmes: {
        institutionInfo: {
          trainingInfo: {
            trainingTotalCount: 0, // 累计培训人数
            trainingTotalTimes: 0, // 累计培训人次
            orgTrainingCount: 0, // 社工组织（培训人数）
            orgTrainingTimes: 0, // 社工组织（培训人次）
            volunteerCount: 0, // 志愿者（培训人数）
            volunteerTimes: 0, // 志愿者（培训人次）
            orgPeopleTotalCount: 0, // 社工组织总人数
            volunteerTotalCount: 0, // 志愿者总人数
          }, // 组织培训
        },
      },
    }
  },
  mounted() {
    this.getStatistics()
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  methods: {
    getStatistics() {
      getStatistics(this.userInfo.areaCode).then((res) => {
        if (res.code == 0) {
          this.insmes = res.data
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.service-title {
  height: 1.46rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0.5rem;
  font-size: 0.6rem;
  font-weight: bold;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #b5bacc;
}
.service {
  color: #ffffff;
  background: url('~@/assets/images/homePage_images/organization.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.3rem;
  position: relative;
  height: 9.6rem;
}
.dachuan_content {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.4rem 0 0.4rem;
  .content_left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 7.2rem;
    box-sizing: border-box;
    .content_item {
      width: 32%;
      height: 3.5rem;
      text-align: center;
      display: inline-block;
      background: url('~@/assets/images/homePage_images/orgleft.png') 0 0
        no-repeat;
      background-size: 100% 100%;
      margin-bottom: 6px;
      p:first-child {
        margin-top: 0.5rem;
        color: #b4d3fe;
        font-size: 1.25rem;
        margin-bottom: 0.1rem;
        font-weight: bold;
      }
      p:last-child {
        color: #8eb8e6;
        font-size: 0.58rem;
      }
    }
  }
}

.spcol {
  color: #ff8529 !important;
}
.common-content {
  display: flex;
  padding: 0.2rem 0.4rem 0 0.4rem;

  .common_left {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    .common_item {
      width: 32%;
      height: 3.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url('~@/assets/images/homePage_images/orgleft.png') 0 0
        no-repeat;
      background-size: 100% 100%;
      margin: 0 0.2rem 0.2rem 0;
      p:first-child {
        margin-top: 0.5rem;
        color: #b4d3fe;
        font-size: 1.25rem;
        margin-bottom: 0.1rem;
        font-weight: bold;
      }
      p:last-child {
        color: #8eb8e6;
        font-size: 0.58rem;
      }
    }
  }
  .common_right {
    width: 25%;
    background: url('~@/assets/images/homePage_images/orgleft.png') 0 0
      no-repeat;
    background-size: 100% 100%;
    margin-bottom: 6px;
    .common_item {
      height: 3.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      p:first-child {
        margin-top: 0.5rem;
        color: #b4d3fe;
        font-size: 1.25rem;
        margin-bottom: 0.1rem;
        font-weight: bold;
      }
      p:last-child {
        color: #8eb8e6;
        font-size: 0.58rem;
      }
    }
  }
}
</style>
