import request from '@/utils/request'

// 用户总数 会员总数 累计消费金额 累计消费人数 月度消费人次 日均消费人次
export const getAreaAll = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/statistics/all',
    method: 'get',
    params: data,
  })

// 获取订单列表
export const getOrderList = (data) =>
  request({
    url: '/v1/screen/gov/order-page',
    method: 'post',
    data: data,
  })

// 获取订单详情
export const getOrderDetail = (orderNo) =>
  request({
    url: '/v1/screen/gov/order-detail?orderNo=' + orderNo,
    method: 'get',
  })

// 获取合同统计数据
export const getContractMessage = (data) =>
  request({
    url: '/v1/screen/gov/contract/statistics',
    method: 'post',
    data,
  })

// 获取合同列表
export const getContractList = (data) =>
  request({
    url: '/v1/screen/gov/contract/all',
    method: 'post',
    data,
  })

// 获取区域下的村镇
export const getAreaLists = (code) =>
  request({
    url: 'ums/v1/area/sub_list?code=' + code,
    method: 'get',
    type: 'ums',
  })

// 获取实时订单列表
export const getServiceList = (code) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/order/top?districtCode=' + code,
    method: 'get',
  })

// 安全异常数据+政府采购居家养老服务数据
export const getSafetyLiving = (code) =>
  request({
    url: '/hp/platform/data/v1/screen/statistics/safety_living',
    method: 'get',
    params: {
      code,
    },
  })

// 获取最新的工单列表
export function workOrderList(data) {
  return request({
    url: '/hp/platform/data/v1/screen/gov/job/tops',
    method: 'get',
    params: data,
  })
}

// 获取政采老人
export const zhengCaiOldMan = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/customer/lng_lat',
    method: 'post',
    data: data,
  })

// 根据地区查询合同
export const getContractData = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/contract/area/list',
    method: 'post',
    needTenantNo: false,
    data: data,
  })

// 获取区域机构数据
export const getAreaList = (code) =>
  request({
    url: '/hp/platform/data/v1/screen/area/ins',
    method: 'get',
    params: {
      code,
    },
  })
