<template>
  <!-- 上侧中间 -->
  <div class="upper-middle-cs">
    <!-- 顶部 -->
    <div class="header">
      <div class="header-table">
        <el-radio-group
          v-model="tabPosition"
          style="margin-bottom: 30px"
          @change="radioChange"
        >
          <el-radio-button label="mechanism">机构地图</el-radio-button>
          <el-radio-button v-auth="'screen:gov:amap'" label="politicalMining"
            >政采地图</el-radio-button
          >
        </el-radio-group>
      </div>
      <div v-if="tabPosition == 'politicalMining'" class="my-select">
        <el-select
          v-model="contractData"
          placeholder="请选择"
          popper-class="popper-c"
          @change="selectChange"
          ref="select"
        >
          <el-option
            v-for="item in options"
            :key="item.globalId"
            :label="item.contractName"
            :value="item.globalId"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="typeContract"
          placeholder="请选择"
          popper-class="popper-c popper-cs"
          @change="selectChangeChild"
          ref="select"
        >
          <el-option
            v-for="item in optionsdata"
            :key="item.globalId"
            :label="item.contractBusinessMark"
            :value="item.globalId"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <!-- 地图 -->
    <div class="my-map">
      <!-- 机构地图 -->
      <organizationMap
        ref="organizationMap"
        :region="region"
        @clickIconfun="communityClick"
        v-if="tabPosition == 'mechanism'"
      ></organizationMap>
      <!-- 政采地图 -->
      <politicalMiningMap
        ref="political"
        :region="region"
        @clickIconfun="clickIconfun"
        v-if="tabPosition == 'politicalMining'"
      ></politicalMiningMap>
    </div>
  </div>
</template>

<script>
// 机构地图
import organizationMap from '@/components/publicMap/index.vue'
// 政采地图
import politicalMiningMap from './politicalMiningMap.vue'
// api
import { getContractData, getAreaList } from '@/api/homePage/middle.js'

export default {
  name: 'upperMiddle',
  components: {
    organizationMap,
    politicalMiningMap,
  },
  data() {
    return {
      // 地图切换
      tabPosition: 'mechanism',
      // 合同列表
      options: [],
      // 主合同id
      contractData: null,
      // 合同类型
      optionsdata: [],
      // 子合同id
      typeContract: null,
      // 区域code
      provinceCode: '',
      cityCode: '',
      districtCode: '',
      region: '',
      // 苍溪打点单独数据
      cangxiData: [
        {
          insNo: '-',
          name: '黄猫敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.41024',
          lat: '32.09339',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '东溪敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.24931',
          lat: '32.03963',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '回水敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '105.94533',
          lat: '31.78911',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '漓江敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.14509',
          lat: '31.96129',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '歧坪敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.15732',
          lat: '31.89152',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '元坝敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.07147',
          lat: '31.82017',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '龙王敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '105.92964',
          lat: '31.74369',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '东青敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '105.87698',
          lat: '31.70504',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '浙水敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '105.89282',
          lat: '31.86174',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '运山敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.30381',
          lat: '31.92449',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '龙山敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.37413',
          lat: '31.87138',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '月山敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.25395',
          lat: '31.82475',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '文昌敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '106.31808',
          lat: '31.97354',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '云峰敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '105.99095',
          lat: '31.69504',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '五龙敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '105.95054',
          lat: '31.92019',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '冬青敬老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '105.95054',
          lat: '31.92019',
          storeType: 1,
        },
        {
          insNo: '-',
          name: '苍溪县社会福利院综合服务中心',
          area: '-',
          spName: '颐伦集团',
          percent: '-',
          lng: '105.92494',
          lat: '31.75412',
          storeType: 0,
          flag: true,
        },
      ],
      // 夹江打点单独数据
      jiajiangData: [
        {
          insNo: '-',
          name: '夹江县社会福利院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.681459',
          lat: '29.692471',
          storeType: 1,
          user: 13274889145,
        },
        {
          insNo: '-',
          name: '夹江县社会福利院甘江一分院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.60138',
          lat: '29.667297',
          storeType: 1,
          user: 13218374122,
        },
        {
          insNo: '-',
          name: '夹江县社会福利院甘江二分院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.601068',
          lat: '29.667343',
          storeType: 1,
          user: 13212345671,
        },
        {
          insNo: '-',
          name: '夹江县社会福利院马村分院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.59297',
          lat: '29.835054',
          storeType: 1,
          user: 13381395219,
        },
        {
          insNo: '-',
          name: '夹江县社会福利院吴场分院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.696067',
          lat: '29.87327',
          storeType: 1,
          user: 13212345623,
        },
        {
          insNo: '-',
          name: '夹江老年养护康复中心',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.590797',
          lat: '29.73063',
          storeType: 1,
          user: 13381396773,
        },
        {
          insNo: '-',
          name: '夹江县潘塘养老院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.582432',
          lat: '29.793786',
          storeType: 1,
          user: 13212399221,
        },
        {
          insNo: '-',
          name: '夹江县同福老人院',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.579377',
          lat: '29.747936',
          storeType: 1,
          user: 13212342371,
        },
        {
          insNo: '-',
          name: '夹江县温馨老年公寓',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.599471',
          lat: '29.739492',
          storeType: 1,
          user: 13381392893,
        },
        {
          insNo: '-',
          name: '夹江县颐伦养老服务有限公司',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.590145',
          lat: '29.730612',
          storeType: 1,
          user: 13381395231,
        },
        {
          insNo: '-',
          name: '紫竹园老年公寓',
          area: '-',
          spName: '-',
          percent: '-',
          lng: '103.59192',
          lat: '29.751904',
          storeType: 1,
          user: 13237988931,
        },
      ],
    }
  },
  computed: {
    boo() {
      let boo = sessionStorage.getItem('cacheOrNot')
      return boo
    },
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  created() {
    this.provinceCode = this.userInfo.provinceCode
    this.cityCode = this.userInfo.cityCode
    this.districtCode = this.userInfo.areaCode
    this.region =
      this.userInfo.province + this.userInfo.city + this.userInfo.area
  },
  mounted() {
    if (this.boo == 0) {
      let obj = {
        provinceCode: this.provinceCode,
        cityCode: this.cityCode,
        districtCode: this.districtCode,
      }
      getContractData(obj).then((res) => {
        if (res.code == 0) {
          if (res.data && res.data.length !== 0) {
            this.tabPosition = 'politicalMining'
            this.$nextTick(() => {
              let data = JSON.parse(sessionStorage.getItem('nameAndId'))
              this.options = res.data
              this.contractData = data.masterGlobalId
              let objs = this.options.find((e) => {
                return e.globalId === this.contractData
              })
              this.optionsdata = objs.contracts
              this.typeContract = data.contractGlobalId
              sessionStorage.setItem('cacheOrNot', false)
              this.$refs.political.getData(data, 0)
            })
          }
        }
      })
    } else {
      this.getData()
    }
  },
  methods: {
    clickIconfun(data) {
      // 子合同
      let objs = this.optionsdata.find((e) => {
        return e.globalId === this.typeContract
      })
      //主合同
      let objsData = this.options.find((e) => {
        return e.globalId === this.contractData
      })
      this.$router.push({
        path: '/elderlyMessage',
        query: {
          customerGlobalId: data.customerGlobalId, // 老人id
          contractGlobalId: this.typeContract, // 子合同id
          contractBusiness: objs.contractBusiness, // 子合同code
          contractBusinessMark: objs.contractBusinessMark, // 子合同名字
          contractName: objsData.contractName, // 主合同名字
        },
      })
    },
    communityClick(data) {
      if (data.storeType == 0 || data.storeType == 1 || data.storeType == 2) {
        if (this.userInfo.areaCode == 510824) {
          // 苍溪
          if (data.flag) {
            window.open('http://yun.jjyl1.com:81/login/login_page')
          }
        } else if (this.userInfo.areaCode == 511126) {
          // 夹江
          window.open(
            `http://yun.jjyl1.com:81/emp/login/foreign?loginName=${data.user}&password=123456`
          )
        } else {
          // 综合体,机构
          this.$router.push({
            path: '/institution/institutionPage',
            query: {
              insNo: data.insNo,
              storeType: data.storeType,
              name: data.name,
            },
          })
        }
      } else if (data.storeType == 3) {
        // 政府采购
        this.$router.push({
          path: '/governmentProcurement',
          query: {
            insNo: data.insNo,
            storeType: data.storeType,
            titleName: data.name,
          },
        })
      }
    },
    getData() {
      if (this.userInfo.areaCode == 510824) {
        // 地图实例化-苍溪
        this.$refs.organizationMap.initializeMap(this.cangxiData)
      } else if (this.userInfo.areaCode == 511126) {
        // 地图实例化-夹江
        this.$refs.organizationMap.initializeMap(this.jiajiangData)
      }
      // 2024-5-21达川区地图标记改为动态获取
      // else if (this.userInfo.areaCode == 511703) {
      //   // 地图实例化-达川
      //   this.$refs.organizationMap.initializeMap(this.dazhouData)
      // }
      else {
        getAreaList(this.userInfo.areaCode).then((res) => {
          console.log('获取地图机构列表', res)
          if (res.code === 0) {
            if (res.data && res.data.length !== 0) {
              let data = res.data
              // 地图实例化
              this.$refs.organizationMap.initializeMap(data)
            } else {
              // 地图实例化
              this.$refs.organizationMap.initializeMap([])
            }
          }
        })
      }
    },
    // 获取合同列表
    getContract() {
      let obj = {
        provinceCode: this.provinceCode,
        cityCode: this.cityCode,
        districtCode: this.districtCode,
      }
      getContractData(obj).then((res) => {
        console.log('合同列表', res)
        if (res.code == 0) {
          if (res.data && res.data.length !== 0) {
            this.options = res.data
            this.contractData = res.data[0].globalId
            this.optionsdata = res.data[0].contracts
            this.typeContract = res.data[0].contracts[0].globalId
            let obj = {
              masterGlobalId: this.contractData,
              contractGlobalId: this.typeContract,
            }
            this.cacheData(obj)
            this.$refs.political.getData(obj, 0)
          } else {
            this.$refs.political.initializeMap([])
          }
        }
      })
    },
    // 切换地图
    radioChange(data) {
      if (data == 'politicalMining') {
        this.getContract()
      } else {
        this.getData()
      }
    },
    // 选择主合同
    selectChange(data) {
      let objs = this.options.find(function (e) {
        return e.globalId === data
      })
      this.contractData = data
      this.optionsdata = objs.contracts
      this.typeContract = objs.contracts[0].globalId
      let obj = {
        masterGlobalId: this.contractData,
        contractGlobalId: this.typeContract,
      }
      this.cacheData(obj)
      this.$refs.political.deleteMarker()
      this.$refs.political.resetPaging()
      this.$refs.political.getData(obj, 1)
    },
    // 选择子合通
    selectChangeChild(data) {
      let objs = this.optionsdata.find(function (e) {
        return e.globalId === data
      })
      this.typeContract = objs.globalId
      let obj = {
        masterGlobalId: this.contractData,
        contractGlobalId: this.typeContract,
      }
      this.cacheData(obj)
      this.$refs.political.deleteMarker()
      this.$refs.political.resetPaging()
      this.$refs.political.getData(obj, 1)
    },
    // 缓存数据
    cacheData(data) {
      sessionStorage.setItem('nameAndId', JSON.stringify(data))
    },
  },
}
</script>

<style scoped lang="scss">
.upper-middle-cs {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 0.4rem;
  position: relative;
  .header-table {
    position: absolute;
    top: 0.7rem;
    left: 0.7rem;
    z-index: 1100;
  }
  .my-select {
    position: absolute;
    right: 0.7rem;
    top: 0.7rem;
    z-index: 1100;
    display: flex;
    align-items: center;
    width: 20rem;
    ::v-deep .el-select:first-child {
      margin-right: 0.5rem;
      flex: 2;
    }
    ::v-deep .el-select:last-child {
      flex: 1;
    }
    ::v-deep .el-input .el-input__inner {
      border: 1px solid #0180ac !important;
      background-color: #00123e;
      color: #4dc2ff;
    }
  }
  .my-map {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: rgba(255, 158, 20, 0.8) !important;
    color: #fff !important;
    box-shadow: '0 0 0 1px #ff9e14,inset 0 0 1px #ff9e14' !important;
    border-color: #ff9e14 !important;
    box-shadow: none !important;
    border: 1px solid #ff9e14 !important;
  }
}
</style>
<style lang="scss">
.popper-c {
  border: 1px solid #0180ac !important;
  background-color: #00123e !important;
  width: 12rem;
}
.popper-cs {
  width: 6rem !important;
}
.popper-c .el-select-dropdown__item.hover,
.popper-c .el-select-dropdown__item:hover {
  background-color: #050f2d !important;
}
.popper-c .popper__arrow {
  display: none !important;
}
.popper-c .el-select-dropdown__item {
  color: #4dc2ff;
}
.popper-c[x-placement^='bottom'] {
  margin-top: 4px;
}

.upper-middle-cs .el-radio-button:first-child .el-radio-button__inner {
  color: #4dc2ff;
  border: 1px solid #0180ac;
  border-right: 1px solid transparent;
  background-color: #01012d;
}

.upper-middle-cs .el-radio-button:last-child .el-radio-button__inner {
  color: #4dc2ff;
  border: 1px solid #0180ac;
  border-left: 1px solid transparent;
  background-color: #01012d;
}
</style>
