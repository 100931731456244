<template>
  <!-- 一级大屏地图 -->
  <div class="publicMapMain" id="publicMapMain">
    <div
      id="publicMap"
      class="publicMap"
      :style="{ width: '100%', height: '100%' }"
    ></div>
    <div class="lowerRight">
      <div
        :class="iSinstitution ? 'lowerRight-item' : 'lowerRight-items'"
        @click="institution('institution')"
      >
        <div><img src="./static/institution.png" alt="" /></div>
        <div>养老机构</div>
      </div>
      <div
        :class="iScomplex ? 'lowerRight-item' : 'lowerRight-items'"
        @click="institution('complex')"
      >
        <div><img src="./static/complex.png" alt="" /></div>
        <div>养老服务综合体</div>
      </div>
      <div
        :class="iSgovernment ? 'lowerRight-item' : 'lowerRight-items'"
        @click="institution('government')"
      >
        <div><img src="./static/governmentProcurement.png" alt="" /></div>
        <div>政府采购项目</div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadBaiDuMap } from '@/utils/baiduUitls'
import bdMapConfig from './config/bdMap_config'
export default {
  props: {
    // 区域中文地点
    region: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iSinstitution: true,
      iScomplex: true,
      iSgovernment: true,
      getList: null, // 标点数据
    }
  },
  computed: {
    // 养老机构：storeType = 1，2
    institutionData: {
      get() {
        let arr = []
        this.getList.forEach((item) => {
          if (item.storeType == 1 || item.storeType == 2) {
            arr.push(item)
          }
        })
        return arr
      },
      set(value) {
        return value
      },
    },
    // 养老服务综合体：storeType = 0
    complexData: {
      get() {
        let arr = []
        this.getList.forEach((item) => {
          if (item.storeType == 0) {
            arr.push(item)
          }
        })
        return arr
      },
      set(value) {
        return value
      },
    },
    // 政府采购项目：storeType = 3
    governmentData: {
      get() {
        let arr = []
        this.getList.forEach((item) => {
          if (item.storeType == 3) {
            arr.push(item)
          }
        })
        return arr
      },
      set(value) {
        return value
      },
    },
  },
  created() {
    bdMapConfig.thisFun(this)
  },
  methods: {
    bDmap: bdMapConfig.bDmap,
    cycleManagement: bdMapConfig.cycleManagement,
    deleteMarker: bdMapConfig.deleteMarker,
    initializeMap(listData) {
      this.getList = listData
      this.loadBaiDuMapFun(this.getList)
    },
    loadBaiDuMapFun(data) {
      loadBaiDuMap({ edition: '3.0' }).then((BMap) => {
        this.bDmap(BMap, data, this.region)
      })
    },
    institution(type) {
      this.deleteMarker()
      if (type == 'institution') {
        console.log('这是机构数据', this.institutionData)
        if (this.iSinstitution) {
          this.iSinstitution = false
        } else {
          this.iSinstitution = true
        }

        this.processingData()
      } else if (type == 'complex') {
        console.log('这是养老服务综合体', this.complexData)
        if (this.iScomplex) {
          this.iScomplex = false
        } else {
          this.iScomplex = true
        }
        this.processingData()
      } else {
        console.log('这是政府采购项目', this.governmentData)
        if (this.iSgovernment) {
          this.iSgovernment = false
        } else {
          this.iSgovernment = true
        }
        this.processingData()
      }
    },
    processingData() {
      if (this.iSinstitution && this.iScomplex && this.iSgovernment) {
        this.cycleManagement(this.getList)
      } else if (!this.iSinstitution && !this.iScomplex && !this.iSgovernment) {
        this.cycleManagement([])
      } else if (this.iSinstitution && !this.iScomplex && !this.iSgovernment) {
        this.cycleManagement(this.institutionData)
      } else if (!this.iSinstitution && this.iScomplex && !this.iSgovernment) {
        this.cycleManagement(this.complexData)
      } else if (!this.iSinstitution && !this.iScomplex && this.iSgovernment) {
        this.cycleManagement(this.governmentData)
      } else if (this.iSinstitution && this.iScomplex && !this.iSgovernment) {
        let arr = []
        this.institutionData.forEach((a) => {
          arr.push(a)
        })
        this.complexData.forEach((b) => {
          arr.push(b)
        })
        this.cycleManagement(arr)
      } else if (this.iSinstitution && !this.iScomplex && this.iSgovernment) {
        let arr = []
        this.institutionData.forEach((a) => {
          arr.push(a)
        })
        this.governmentData.forEach((b) => {
          arr.push(b)
        })
        this.cycleManagement(arr)
      } else if (!this.iSinstitution && this.iScomplex && this.iSgovernment) {
        let arr = []
        this.complexData.forEach((a) => {
          arr.push(a)
        })
        this.governmentData.forEach((b) => {
          arr.push(b)
        })
        this.cycleManagement(arr)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import './style/index.scss';
</style>
