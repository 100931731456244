<template>
  <div>
    <div class="service">
      <div>
        <div class="service-title"></div>
      </div>
      <div class="service-main">
        <div class="boxitem1">
          <div class="item">
            <div class="title">机构数据</div>
            <div class="conten">
              <ul>
                <li>
                  机构总数
                  <span class="spred">{{
                    insmes.institutionInfo.storeInfo.totalStoreCount
                  }}</span>
                </li>
                <li>
                  公办机构
                  <span>{{
                    insmes.institutionInfo.storeInfo.publicStoreCount
                  }}</span>
                </li>
                <li>
                  民办机构
                  <span>{{
                    insmes.institutionInfo.storeInfo.privateStoreCount
                  }}</span>
                </li>
                <li>
                  医养中心
                  <span>{{
                    insmes.institutionInfo.storeInfo.medicalCenterCount
                  }}</span>
                </li>
                <li>
                  失能半失能中心
                  <span>{{
                    insmes.institutionInfo.storeInfo.halfDisabledCenterCount
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="title">床位数据</div>
            <div class="conten">
              <ul>
                <li>
                  床位总数
                  <span class="spred">{{
                    insmes.institutionInfo.bedInfo.totalBedCount
                  }}</span>
                </li>
                <li>
                  公办养老机构
                  <span>{{
                    insmes.institutionInfo.bedInfo.publicBedCount
                  }}</span>
                </li>
                <li>
                  民办养老机构
                  <span>{{
                    insmes.institutionInfo.bedInfo.privateBedCount
                  }}</span>
                </li>
                <li>
                  医养中心
                  <span>{{
                    insmes.institutionInfo.bedInfo.medicalCenterBedCount
                  }}</span>
                </li>
                <li>
                  失能半失能中心
                  <span>{{
                    insmes.institutionInfo.bedInfo.halfDisabledBedCount
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="title">入住数据</div>
            <div class="conten">
              <ul>
                <li>
                  入住总数
                  <span class="spred">{{
                    insmes.institutionInfo.stayInfo.totalStayCount
                  }}</span>
                </li>
                <li>
                  公办养老机构
                  <span>{{
                    insmes.institutionInfo.stayInfo.publicStayCount
                  }}</span>
                </li>
                <li>
                  民办养老机构
                  <span>{{
                    insmes.institutionInfo.stayInfo.privateStayCount
                  }}</span>
                </li>
                <li>
                  医养中心
                  <span>{{
                    insmes.institutionInfo.stayInfo.medicalStayCount
                  }}</span>
                </li>
                <li>
                  失能半失能中心
                  <span>{{
                    insmes.institutionInfo.stayInfo.halfDisabilityStayCount
                  }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="boxitem2">
          <div class="item">
            <div class="title-main">
              <div class="title">服务人员</div>
              <div class="title">长者评估</div>
            </div>
            <div class="conten">
              <div class="conten-c">
                <ul>
                  <li>
                    总数据
                    <span class="spred">{{
                      insmes.institutionInfo.staffInfo.totalCount
                    }}</span>
                  </li>
                  <li>
                    执业医师指标
                    <span>{{
                      insmes.institutionInfo.staffInfo.doctorCount
                    }}</span>
                  </li>
                  <li>
                    护士护工数据
                    <span>{{
                      insmes.institutionInfo.staffInfo.nurseCount
                    }}</span>
                  </li>
                  <li>
                    护理人员数
                    <span>{{
                      insmes.institutionInfo.staffInfo.careCount
                    }}</span>
                  </li>
                </ul>
              </div>

              <div class="data_box">
                <div
                  id="occupancyRatio"
                  style="width: 100%; height: 100%"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let echarts = require('echarts')
import { getStatistics } from '@/api/homePage/right.js'

export default {
  data() {
    return {
      insmes: {
        institutionInfo: {
          storeInfo: {
            totalStoreCount: 0, // 机构总数
            publicStoreCount: 0, // 公办机构
            privateStoreCount: 0, // 民办机构
            medicalCenterCount: 0, // 医养中心
            halfDisabledCenterCount: 0, //失能半失能中心
          }, // 机构数据
          bedInfo: {
            totalBedCount: 0, // 床位总数
            publicBedCount: 0, // 公办养老机构
            privateBedCount: 0, // 民办养老机构
            medicalCenterBedCount: 0, // 医养中心
            halfDisabledBedCount: 0, // 失能半失能中心
          }, // 床位数据
          stayInfo: {
            totalStayCount: 0, // 入住总数
            publicStayCount: 0, // 公办养老机构
            privateStayCount: 0, // 民办养老机构
            medicalStayCount: 0, // 医养中心
            halfDisabilityStayCount: 0, // 失能半失能中心
          }, // 入住数据
          staffInfo: {
            totalCount: 0, // 入住总数
            doctorCount: 0, // 执业医师
            nurseCount: 0, // 护士护工
            careCount: 0, // 护理人员
          }, // 服务人员
          assessInfo: {
            selfCount: 0, // 自理人数
            selfRate: 0, // 自理比例
            helpCount: 0, // 介助人数
            helpRate: 0, // 介助比例
            careCount: 0, // 介护人数
            careRate: 0, // 介护比例
            specialCount: 0, // 特护人数
            specialRate: 0, // 特护比例
          }, // 长者评估
        },
      },
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  mounted() {
    this.getStatistics()
  },
  methods: {
    getStatistics() {
      getStatistics(this.userInfo.areaCode).then((res) => {
        if (res.code == 0) {
          this.insmes = res.data
          this.getchart()
        }
      })
    },
    getchart() {
      let { selfRate, helpRate, careRate, specialRate } =
        this.insmes.institutionInfo.assessInfo
      let data = [
        {
          name: '自理',
          value: selfRate,
        },
        {
          name: '介助',
          value: helpRate,
        },
        {
          name: '介护',
          value: careRate,
        },
        {
          name: '特护',
          value: specialRate,
        },
      ]
      var occupancyRatio = echarts.init(
        document.getElementById('occupancyRatio')
      )
      // 绘制图表
      occupancyRatio.setOption({
        color: [
          '#FF9F1B',
          '#30F279',
          '#359DFF',
          '#FF35F0',
          '#bba840',
          '#ee82ee',
        ],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {d}%',
        },
        legend: {
          orient: 'vertical',
          left: 10,
          top: 20,
          bottom: 20,
          textStyle: {
            color: '#B4C6FE',
          },
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['60%', '55%'],
            label: {
              formatter: '{b}\n {d}%',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            data: data,
          },
        ],
      })
    },
  },
}
</script>
<style scoped lang="scss">
.service {
  color: #ffffff;
  background: url('~@/assets/images/homePage_images/region.png') 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.3rem;
  position: relative;
  height: 18.1rem;
}
.service-title {
  height: 1.56rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-main {
  padding: 0.5rem 0.4rem;
}
.boxitem1 {
  display: flex;
  justify-content: space-between;
}
.boxitem2 {
  margin-top: 0.7rem;
  .title-main {
    display: flex;
    .title:first-child {
      width: 7.5rem;
    }
  }
}

.boxitem1 .item .conten {
  height: 6.9rem;
  padding: 0.2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 7.3rem;
  background: url('~@/assets/images/homePage_images/boxitem1.png') 0 0 no-repeat;
  background-size: 100% 100%;
}
.boxitem2 .conten {
  display: flex;
  height: 5.8rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  .conten-c {
    display: flex;
    align-items: center;
    border: 1px solid #213060;
  }
  .data_box {
    width: calc(100% - 7.4rem);
    border: 1px solid #213060;
  }
}
.boxitem2 .conten ul {
  width: 7.18rem;
}

.boxitem1 .item .title,
.boxitem2 .item .title {
  color: #69b1ff;
  font-size: 0.58rem;
  margin-bottom: 0.3rem;
  font-weight: bold;
}
.conten ul {
  width: 100%;
}
.conten ul li {
  color: #8eb8e6;
  font-size: 0.58rem;
  padding: 0 0.4rem;
  height: 1.2rem;
  line-height: 1.2rem;
}
.conten ul li .spred {
  color: #ff8529;
}
.conten ul li span {
  padding-right: 0.8rem;
  float: right;
  font-size: 0.58rem;
}
</style>
