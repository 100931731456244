<template>
  <!-- 下侧中右 -->
  <div class="under-security-cs">
    <div class="left-box-top"></div>
    <div class="security-cs">
      <el-carousel :autoplay="false" indicator-position="none" height="11rem">
        <el-carousel-item
          v-for="(item, index) in safty.securityInfo"
          :key="index"
        >
          <div class="smalltitle-cs">({{ item.storeName }})</div>
          <ul class="csdul-cs">
            <li>
              <span
                :style="
                  !item.fireSafeStatus ? 'color:#b63219' : 'color: #8EB8E6'
                "
                >消防安全</span
              >
              <div
                :style="
                  !item.fireSafeStatus == '异常'
                    ? 'color:#b63219'
                    : 'color:#8EB8E6'
                "
              >
                <span
                  :class="
                    !item.fireSafeStatus == '异常' ? 'dian red' : 'dian gren'
                  "
                ></span>
                <span>{{ item.fireSafeStatus ? '正常' : '异常' }}</span>
              </div>
            </li>
            <li>
              <span
                :style="
                  !item.monitorSafeStatus ? 'color:#b63219' : 'color: #8EB8E6'
                "
                >监控安全</span
              >
              <div
                :style="
                  !item.monitorSafeStatus ? 'color:#b63219' : 'color:#8EB8E6'
                "
              >
                <span
                  :class="!item.monitorSafeStatus ? 'dian red' : 'dian gren'"
                ></span>
                <span>{{ item.monitorSafeStatus ? '正常' : '异常' }}</span>
              </div>
            </li>
            <li>
              <span
                :style="
                  !item.accessSafeStatus ? 'color:#b63219' : 'color: #8EB8E6'
                "
                >门禁安全</span
              >
              <div
                :style="
                  !item.accessSafeStatus ? 'color:#b63219' : 'color:#8EB8E6'
                "
              >
                <span
                  :class="!item.accessSafeStatus ? 'dian red' : 'dian gren'"
                ></span>
                <span>{{ item.accessSafeStatus ? '正常' : '异常' }}</span>
              </div>
            </li>
            <li>
              <span
                :style="
                  !item.infoSafeStatus ? 'color:#b63219' : 'color: #8EB8E6'
                "
                >信息安全</span
              >
              <div
                :style="
                  !item.infoSafeStatus ? 'color:#b63219' : 'color:#8EB8E6'
                "
              >
                <span
                  :class="!item.infoSafeStatus ? 'dian red' : 'dian gren'"
                ></span>
                <span>{{ item.infoSafeStatus ? '正常' : '异常' }} </span>
              </div>
            </li>
            <li>
              <span
                :style="
                  !item.envSafeStatus ? 'color:#b63219' : 'color: #8EB8E6'
                "
                >环境安全</span
              >
              <div
                :style="!item.envSafeStatus ? 'color:#b63219' : 'color:#8EB8E6'"
              >
                <span
                  :class="!item.envSafeStatus ? 'dian red' : 'dian gren'"
                ></span>
                <span>{{ item.envSafeStatus ? '正常' : '异常' }}</span>
              </div>
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>
      <div
        class="noMessage"
        v-if="!safty.securityInfo || safty.securityInfo.length === 0"
      >
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { getStatistics } from '@/api/homePage/right.js'
export default {
  data() {
    return {
      // 安全系统实时数据
      safty: {
        securityInfo: [
          // {
          //   storeName: '蒙自2023适老化改造', // 门店名称
          //   fireSafeStatus: true, // 消防安全
          //   monitorSafeStatus: true, // 监控安全
          //   accessSafeStatus: true, // 门禁安全
          //   infoSafeStatus: true, // 信息安全
          //   envSafeStatus: true, // 环境安全
          // }, // 消防安全
        ],
      },
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      getStatistics(this.userInfo.areaCode).then((res) => {
        if (res.code === 0) {
          this.safty = res.data
        }
      })
    },
  },
}
</script>

<style scoped>
.under-security-cs {
  background: url('~@/assets/images/homePage_images/safebg.png') 0 0 no-repeat;
  background-size: 100% 100%;
  width: 7.3rem;
  height: 13rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  display: inline-block;
}
.left-box-top {
  height: 1.56rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title,
.smalltitle-cs {
  color: #69b1ff;
  font-size: 0.58rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.csdul-cs li {
  border-radius: 2px;
  height: 1.58rem;
  padding: 0 0.4rem;
  border: 1px solid #213060;
  background: rgba(5, 13, 38, 0.31);
  margin-bottom: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dian {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 0.4rem;
  border-radius: 100%;
}
.gren {
  background: #32cf57;
}
.red {
  background: red;
}

.noMessage {
  position: absolute;
  font-size: 21px;
  right: 1.9rem;
  top: 5rem;
  color: #2d3769;
}
</style>
<style>
/* 下侧中右 */
.under-security-cs {
  width: 13%;
  margin-right: 0.3rem;
  position: relative;
}
</style>
