// const ak = 'ZBi45KwtSWrZTxZCSIcCc9GGZ5AC1t1X' // 百度的地图密钥
const ak = 'u410QdVVHc8MwPM9ih5lkLCMzHxGvmLC' // 百度的地图密钥
/**
 * 异步加载百度地图
 * @returns {Promise}
 * @constructor
 */
function loadBaiDuMap(edition) {
  return new Promise(function(resolve, reject) {
    try {
      console.log('BMap is defined:', BMapGL === undefined || BMapGL)
      resolve(BMapGL)
    } catch (err) {
      window.init = function() {
        resolve(BMapGL)
      }
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://api.map.baidu.com/api?v=${edition.edition}&type=webgl&ak=${ak}&callback=init`
      script.onerror = reject
      document.body.appendChild(script)
    }
  })
}
export { loadBaiDuMap }
/**
 * 异步加载百度地图,以及绘制工具
 * @returns {Promise}
 * @constructor
 */
function loadBaiDuDrawMap(edition) {
  return loadBaiDuMap(edition).then((BMapGL) => {
    let loaded = false
    try {
      loaded = BMapGLLib && BMapGLLib.DrawingManager
    } catch (err) {
      loaded = false
    }
    if (!loaded) {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.src =
        'https://mapopen.cdn.bcebos.com/github/BMapGLLib/DrawingManager/src/DrawingManager.min.js'
      document.body.appendChild(script)
      let link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href =
        'https://mapopen.cdn.bcebos.com/github/BMapGLLib/DrawingManager/src/DrawingManager.min.css'
      document.body.appendChild(link)
    } else {
      console.log('BMapLib.DrawingManager is loaded!')
    }
    return BMapGL
  })
}
export { loadBaiDuDrawMap }
