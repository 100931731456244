<template>
  <div class="middleItem">
    <areaMap></areaMap>
    <project></project>
  </div>
</template>
<script>
import areaMap from './components/areaMap.vue'
import project from './components/project.vue'
export default {
  components: {
    areaMap,
    project,
  },
}
</script>
<style scoped></style>
