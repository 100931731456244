<template>
  <!-- 区域地图 -->
  <div class="areaMap">
    <div class="all-statistics" v-if="topShow">
      <div class="statistics-item">
        <div class="fist-item">
          {{ numberFormat(statisticsData.totals) }}
        </div>
        <div>用户总数</div>
      </div>
      <div class="statistics-item">
        <div>
          {{ numberFormat(statisticsData.vipTotal) }}
        </div>
        <div>会员总数</div>
      </div>
      <div class="statistics-item">
        <div>
          {{ numberFormat(statisticsData.amount) }}
        </div>
        <div>累计消费金额</div>
      </div>
      <div class="statistics-item">
        <div>
          {{ numberFormat(statisticsData.total) }}
        </div>
        <div>累计消费人数</div>
      </div>
      <div class="statistics-item">
        <div>
          {{ numberFormat(statisticsData.monthTotal) }}
        </div>
        <div>月度消费人次</div>
      </div>
      <div class="statistics-item">
        <div>
          {{ numberFormat(statisticsData.dayTotal) }}
        </div>
        <div>日均消费人次</div>
      </div>
    </div>
    <div class="map-box" :style="{ height: mapHeight }">
      <myMap />
    </div>
  </div>
</template>
<script>
import { getAreaAll } from '@/api/homePage/middle.js'
import myMap from './map.vue'

export default {
  components: {
    myMap,
  },
  data() {
    return {
      statisticsData: {
        amount: 0, // 累计消费金额
        totals: 0, // 用户总数
        vipTotal: 0, // 会员总数
        dayTotal: 0, // 日均消费人次
        monthTotal: 0, // 月度消费人次
        total: 0, // 累计消费人数
      },
      topShow: false,
      mapHeight: '27.5rem',
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  mounted() {
    this.getAreaAll()
  },
  methods: {
    // 获取到统计信息
    getAreaAll() {
      getAreaAll({ code: this.userInfo.areaCode }).then((res) => {
        if (res.code == 0) {
          if (this.userInfo.areaCode == 511703) {
            this.topShow = false
            this.mapHeight = '27.5rem'
          } else {
            this.topShow = true
            this.mapHeight = '24rem'
            let data = res.data
            this.statisticsData = {
              amount: data.amount,
              totals: data.customer.total,
              vipTotal: data.customer.vipTotal,
              dayTotal: data.dayTotal,
              monthTotal: data.monthTotal,
              total: data.total,
            }
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    detailClick(data) {
      data['code'] = this.$route.query.code
      data['companyName'] = this.$route.query.name
      this.$router.push({
        path: '/insComplex',
        query: data,
      })
    },
    numberFormat(value) {
      var param = {}
      var k = 10000,
        sizes = ['', '万', '亿', '万亿'],
        i
      if (Number(value) < k) {
        param.value = value
        param.unit = ''
      } else {
        i = Math.floor(Math.log(value) / Math.log(k))
        param.value = (value / Math.pow(k, i)).toFixed(2)
        param.unit = sizes[i]
      }
      return param.value + param.unit
    },
  },
}
</script>
<style scoped lang="scss">
.areaMap {
  background: url('~@/assets/images/homePage_images/mapback.png') 0 0 no-repeat;
  background-size: 100% 100%;
  height: 28rem;
  margin-bottom: 0.3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .all-statistics {
    display: flex;
    justify-content: space-around;
    background: rgba(5, 13, 38, 0.31);
    border-radius: 2px;
    border: 1px solid #213060;
    margin-top: 0.4rem;
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    .statistics-item > div:first-child {
      font-size: 1rem;
      line-height: 1rem;
    }
    .statistics-item > div:nth-child(2) {
      line-height: 1rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8eb8e6;
    }
    .statistics-item {
      display: flex;
      font-family: DINAlternate-Bold, DINAlternate;
      font-weight: bold;
      color: #b4d3fe;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;
      .fist-item {
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #ff8529;
      }
    }
  }

  .map-box {
    width: 31.3rem;
  }
}
</style>
