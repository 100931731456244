<template>
  <div style="display: flex">
    <img
      src="@/assets/images/homePage_images/changeArea.png"
      style="cursor: pointer; margin-right: 0.3rem"
      @click="changeArea"
      v-if="hasAreaChange"
    />
    <img
      src="@/assets/images/homePage_images/loginOut.png"
      style="cursor: pointer; margin-right: 0.3rem"
      @click="logout"
    />
  </div>
</template>

<script>
import { getTenantList } from '@/api/user'
export default {
  methods: {
    changeArea() {
      getTenantList().then((res) => {
        if (res.code == 0) {
          let areaList = []
          areaList = res.data.map((item) => {
            return {
              name: item.name,
              code: item.areaCode,
              tenantNo: item.tenantNo,
            }
          })
          areaList = areaList.filter((item) => {
            return item.name
          })
          sessionStorage.setItem('hasAreaList', JSON.stringify(areaList))
          this.$router.push('/login?switch=' + true)
        }
      })
    },
    logout() {
      this.$confirm('您确认要退出登录吗?', '退出登录?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          this.$message({
            type: 'success',
            message: '退出登录成功',
          })
          await this.$store.dispatch('user/logout')
          this.$router.push(`/login`)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出登录',
          })
        })
    },
  },
  data() {
    return {
      hasAreaChange: false,
    }
  },
  created() {
    const hasAreaChange = JSON.parse(sessionStorage.getItem('hasAreaList'))
    if (hasAreaChange && hasAreaChange.length > 2) {
      this.hasAreaChange = true
    } else {
      this.hasAreaChange = false
    }
  },
}
</script>

<style></style>
