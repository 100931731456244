<template>
  <div class="leftItem main">
    <populationOverview />
    <olderInfor />
    <comprehensive />
  </div>
</template>
<script>
export default {
  components: {
    populationOverview: () => import('./component/populationOverview.vue'),
    olderInfor: () => import('./component/olderInfor.vue'),
    comprehensive: () => import('./component/comprehensive.vue'),
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
}
</script>
<style scoped>
.main {
  width: 24rem;
}
</style>
