<template>
  <!-- 下侧右边 -->
  <div class="under-right-cs">
    <div class="left-box-top"></div>
    <div class="left-box-bottom"></div>
    <!-- 标题 -->
    <template class="right-title-cs"></template>
    <!-- 监控 -->
    <div class="tabChange">
      <!-- 视屏 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="item.name"
          :name="item.insNo"
          v-for="item in insMessage"
          :key="item.id"
        >
          <div class="video-cs" v-if="item.insNo == activeName">
            <div class="mask" @click="monitoringButton(item)" v-if="urls"></div>
            <iframe
              v-if="urls"
              :src="
                `https://open.ys7.com/ezopen/h5/iframe_se?url=${urls}&audio=1&accessToken=` +
                token
              "
              style="margin-top: -28%"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              border="0"
              name="alimamaifrm"
              scrolling="no"
              height="172%"
              width="98%"
              id="iframe"
              allowfullscreen
            >
            </iframe>
            <div class="ifram-top">
              <span v-if="!urls"> {{ loading }} </span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div v-if="this.insMessage.length === 0" class="noMessage">
        监控暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { getDevices, getToken, getMonitoringFun } from '@/api/homePage/right.js'
export default {
  data() {
    return {
      code: '',
      name: '',
      urls: null,
      data: {},
      activeName: '',
      insMessage: [],
      token: '',
      loading: '加载中',
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    // 获取播放地址
    handleClick: _.debounce(async function (tab, event) {
      await this.handleGetToken(tab.name)
      this.getMonitoringMessage(tab.name)
    }, 1000),
    handleGetToken(data) {
      return new Promise((resolve, reject) => {
        getToken(data).then((res) => {
          if (res.code == 0) {
            this.token = res.data
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    getData() {
      const data = {
        code: this.userInfo.areaCode,
      }
      this.code = this.userInfo.areaCode
      getDevices(data).then(async (res) => {
        if (res.code === 0) {
          if (res.data && res.data.length !== 0) {
            this.insMessage = res.data
            //二次修改 2024-5-16
            // 三次修改 2024-5月29 修改排序
            let arr = [ 
              {
                insNo: 'SP0020HP0007',
                storeType: 1,
                name: '管村中心敬老院',
                deviceNum: 5,
              },
              {
                deviceNum: 6,
                insNo: 'SP0020HP0010',
                name: '渡市中心敬老院',
                storeType: 1,
              },
              {
                insNo: 'SP0020HP0005',
                storeType: 2,
                name: '欢歌康养中心',
                deviceNum: 30,
              },
            ]
            let objs = {
              insNo: 'SP0020HP0011',
              storeType: 1,
              name: '达州市达川区赵家镇敬老院',
              deviceNum: 7,
            }
            if (this.userInfo.areaCode == '511703') {
              //  2024-5-16再次修改摄像头排序 无法显示摄像头的放最后，都市放前面
              for (let i = this.insMessage.length - 1; i >= 0; i--) {
                if (
                  this.insMessage[i].insNo == 'SP0020HP0011' ||
                  this.insMessage[i].insNo == 'SP0020HP0007' ||
                  this.insMessage[i].insNo == 'SP0020HP0010' ||
                  this.insMessage[i].insNo == 'SP0020HP0005'
                ) {
                  this.insMessage.splice(i, 1)
                }
              }
              let datas = [...arr, ...this.insMessage]
              let datass = _.cloneDeep(datas)
              datass.unshift(objs)
              this.insMessage = datass
            }
            this.activeName = this.insMessage[0].insNo
            await this.handleGetToken(this.insMessage[0].insNo)
            this.getMonitoringMessage(this.insMessage[0].insNo)
          }
        }
      })
    },
    getMonitoringMessage(data) {
      this.urls = null
      this.loading = '加载中...'
      getMonitoringFun({ page: 1, perPage: 10, institutionNo: data }).then(
        (res) => {
          if (res.code == 0) {
            if (res.data == null ? true : res.data.length == 0 ? true : false) {
              this.urls = null
            } else {
              // if (this.userInfo.areaCode == '511703') {
              //   if (this.activeName == 'SP0020HP0011') {
              //     this.urls = 'ezopen://open.ys7.com/C70507121/1.hd.live'
              //   } else {
              //     this.urls = res.data[0].url
              //   }
              // } else {
              this.urls = res.data[0].url
              // }
              this.loading = this.urls ? '加载中...' : '暂无数据'
            }
          }
        }
      )
    },
    // 标题按钮
    monitoringButton(item) {
      this.$router.push('/videopage?insNo=' + item.insNo)
    },
  },
}
</script>

<style scoped>
.tabChange {
  width: 100%;
  box-sizing: border-box;
}
.under-right-cs {
  background: url('~@/assets/images/homePage_images/videobg.png') 0 0 no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  width: calc(100% - 7.6rem);
  height: 13rem;
  /* float: right; */
}

.under-right-cs .right-title-cs {
  display: flex;
  justify-content: center;
  margin: 0rem 0.1rem 0rem 0.1rem;
  cursor: pointer;
}

.under-right-cs .right-title-cs > span {
  font-size: 0.8rem;
}

.under-right-cs .video-cs {
  height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.video-cs {
  margin-top: 2%;
  cursor: pointer;
}
.ifram-top {
  height: 10rem;
  width: 100%;
  position: absolute;
  z-index: 999;
  right: 0.7rem;
  bottom: 0rem;
  top: 2rem;
}
.ifram-top > span {
  font-size: 24px;
  color: #2d3769;
  position: absolute;
  right: 5.2rem;
  top: 2.5rem;
}

#iframe {
  border: 0px;
  vertical-align: bottom;
  background: #3d3d3d;
}
.noMessage {
  position: absolute;
  color: #2d3769;
  font-size: 0.9rem;
  right: 4.6rem;
  top: 5.15rem;
}
.mask {
  position: absolute;
  height: 12rem;
  width: 15rem;
  background: transparent;
  z-index: 1100;
}
</style>
<style>
.el-tabs__nav-next,
.el-tabs__nav-prev {
  line-height: 35px;
}
.el-tabs__item {
  color: #ffd725;
  font-weight: 500 !important;
  font-size: 0.65rem;
  padding: 0 10px;
  height: 33px;
  line-height: 33px;
}
/* 下侧右边 */
.under-right-cs {
  width: 20%;
  position: relative;
}

.right-title-cs {
  height: 1.5rem;
  background: url('~@/assets/images/homePage_images/frametop.png') no-repeat
    center;
  background-size: 94% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-title-cs > span {
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00d5ff;
  font-size: 1rem;
  background: linear-gradient(
    92deg,
    #0072ff 0%,
    #00eaff 48.8525390625%,
    #01aaff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.el-tabs__header {
  margin: 0;
}
.el-tabs__nav-wrap {
  padding: 0 0.4rem;
}
.el-tabs__nav-wrap::after {
  height: 0px;
}

.el-tabs__active-bar {
  background-color: none;
}
.el-tabs__item {
  color: #8eb8e6;
}
.el-tabs__item:hover,
.el-tabs__item.is-active {
  color: #ffffff;
}
.under-right-cs {
  display: flex;
  justify-content: center;
}
</style>
