<template>
  <!-- 政采地图 -->
  <div class="poMap">
    <div id="politicalMiningMap" class="politicalMiningMap"></div>
    <div class="change-batch" @click="changeBatch">
      <div class="batch">
        <img src="@/assets/images/homePage_images/peopleImg.png" alt="" />
        <div>换一批长者</div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadBaiDuMap } from '@/utils/baiduUitls'
import { zhengCaiOldMan } from '@/api/homePage/middle.js'
import map_M from '@/assets/images/homePage_images/oldman.png'
export default {
  props: {
    // 区域中文地点
    region: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maps: null,
      myBMap: null,
      page: 1,
      perPage: 500,
      totalPage: 0,
      contractGlobalId: '',
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  methods: {
    // 获取政采老人
    getData(e, type) {
      this.contractGlobalId = e
      let obj = {
        page: this.page, // 当前页
        perPage: this.perPage, // 每页显示多少条
        contractGlobalId: e.contractGlobalId, // 合同id
        districtCode: this.userInfo.areaCode, // code数据
      }
      zhengCaiOldMan(obj).then((res) => {
        console.log('获取老人列表', res)
        if (res.code == 0) {
          let data = res.data ? res.data : []
          this.totalPage = res.totalPage
          if (type == 0) {
            this.initializeMap(data)
          } else {
            this.cycleManagement(res.data)
          }
        }
      })
    },
    changeBatch() {
      if (this.page == this.totalPage) {
        this.page = 1
        this.deleteMarker()
        this.getData(this.contractGlobalId, 1)
      } else {
        this.page = this.page + 1
        this.deleteMarker()
        this.getData(this.contractGlobalId, 1)
      }
    },
    // 重置分页
    resetPaging() {
      this.page = 1
      this.perPage = 500
    },
    initializeMap(listData) {
      loadBaiDuMap({ edition: '3.0' }).then((BMap) => {
        this.bDmap(BMap, listData, this.region)
      })
    },
    bDmap(BMaps, ele, regionData) {
      // 赋值构造地图实例方法
      let BMap = BMaps
      this.myBMap = BMap
      // 创建Map实例
      let map = new BMap.Map('politicalMiningMap')
      this.maps = map
      // 绘制行政区划轮廓
      let region = regionData
      this.drawPolygon(region, ele, BMap, map)
      // 启用滚轮放大缩小
      map.enableScrollWheelZoom(true)
    },
    drawPolygon(regionName, ele, BMap, map) {
      let bdary = new BMap.Boundary()
      let that = this
      bdary.get(regionName, function (rs) {
        //轮廓的总数有的区由多个封闭区域组成
        let count = rs.boundaries.length
        if (!count) {
          console.log('未能获取到当前输入的行政区域')
          return
        }
        // 循环绘制
        for (let i = 0; i < count; i++) {
          //创建多边形覆盖物
          let ply = new BMap.Polygon(rs.boundaries[i], {
            strokeWeight: 3,
            strokeColor: '#368EFF',
            strokeStyle: 'solid',
            fillOpacity: 0.15,
            fillColor: '#368EFF',
          })
          if (i == 0) {
            //调整视野
            map.setViewport(ply.getPath())
          }
          //添加覆盖物
          map.addOverlay(ply)
        }
        // 百度地图自定义样式
        map.setMapStyleV2({
          styleId: 'f3cd572fe93000d35eb96c8b04dc0c3a',
        })
        // 循环地图打点
        that.cycleManagement(ele)
      })
    },
    deleteMarker() {
      const allOverlays = this.maps.getOverlays()
      for (let i = 0; i < allOverlays.length; i++) {
        if (i !== 0) {
          this.maps.removeOverlay(allOverlays[i])
        }
      }
    },
    cycleManagement(ele) {
      ele.forEach((item) => {
        this.punctuationPopup(item, this.myBMap, this.maps)
      })
    },
    punctuationPopup(item, BMap, map) {
      var point = new BMap.Point(item.lng, item.lat)
      // 创建点标记
      let imgIcon = map_M
      var myIcon = new BMap.Icon(imgIcon, new BMap.Size(32, 40), {
        anchor: new BMap.Size(10, 25),
      })
      var marker = new BMap.Marker(point, { icon: myIcon })
      map.addOverlay(marker)
      // 点标记添加点击事件
      let that = this
      marker.addEventListener('click', function () {
        // 标记点添加点击事件(查看档案)
        that.clickIconfun(item)
      })
      //设置文本标记
      var opts2 = {
        position: point, // 指定文本标注所在的地理位置
        offset: new BMap.Size(5, 18), // 设置文本偏移量
      }
      let content = `
        <div>
            <div class='labelSty' style="transform: translateX(-50%);position: absolute;left: 50%;color: #FFFFFF;font-size: 12px;">
            ${item.name}
            </div>
        </div>
      `
      // 创建文本标注对象
      var label = new BMap.Label(content, opts2)
      map.addOverlay(label)
    },
    clickIconfun(data) {
      console.log('点击图标', data)
      this.$emit('clickIconfun', data)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .BMap_bubble_pop {
  border-radius: 0px !important;
  background-color: transparent !important;
  border: 0px !important;
  width: 360px !important;

  img {
    height: 0 !important;
    display: none !important;
  }
}

::v-deep .anchorBL {
  display: none;
}
::v-deep .BMap_bubble_buttons {
  display: none !important;
}

::v-deep .BMap_bubble_top {
  height: 0px !important;
}

::v-deep .labelSty {
  font-size: 12px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #595959;
}

::v-deep .BMapLabel {
  border: none !important;
  background: transparent !important;
}
.poMap {
  height: 100%;
  width: 100%;
  position: relative;
  .politicalMiningMap {
    height: 100%;
    width: 100%;
  }

  .change-batch {
    background: url('~@/assets/images/homePage_images/peopleback.png') 0 0
      no-repeat;
    background-size: 100% 100%;
    position: absolute;
    color: #ffffff;
    right: 0.5rem;
    bottom: 0.8rem;
    padding: 0.3rem 1rem;
    border: 1px solid #fff;
    z-index: 66;
    cursor: pointer;
    .batch {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
