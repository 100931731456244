import request from '@/utils/request'

// 区县code或机构码获取视频设备
export const getDevices = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/ins/devices',
    method: 'post',
    data: data,
  })

//获取token
export const getToken = (institutionNo) =>
  request({
    url: `/hp/platform/data/v1/screen/gov/ysy/accessToken/${institutionNo}`,
    method: 'get',
  })

//   获取设备地址列表
export const getMonitoring = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/live/list',
    method: 'get',
    params: data,
  })

// 获取设备地址列表
export const getMonitoringFun = (data) =>
  request({
    url: '/hp/platform/data/v1/screen/gov/live/page',
    method: 'post',
    data: data,
  })

// 区域内养老机构概况+组织培训
export const getAreaInsProfile = (code) =>
  request({
    url: '/hp/platform/data/v1/screen/statistics/insProfile',
    method: 'get',
    params: {
      code,
    },
  })

// 安全异常数据+政府采购居家养老服务数据
export const getSafetyLiving = (code) =>
  request({
    url: 'hp/platform/data/v1/screen/statistics/safety_living',
    method: 'get',
    params: {
      code,
    },
  })

// 统计数据集合
export const getStatistics = (code) =>
  request({
    url: '/hp/platform/data/v1/screen/mock-data?level=1',
    method: 'get',
    params: {
      districtCode: code,
    },
  })
