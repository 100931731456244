<template>
  <!-- 预警图标跳转预警中心 -->
  <div class="warningIcon" @click="clickIcon">
    <img v-if="warningIcon" src="./static/anIcon.png" alt="" />
    <img v-else src="./static/liangIcom.png" alt="" />
  </div>
</template>
<script>
import { workOrderList } from '@/api/homePage/middle.js'
export default {
  data() {
    return {
      warningIcon: true,
    }
  },
  mounted() {
    this.punctuationData()
  },
  computed: {
    insNo() {
      return this.$route.query.insNo
    },
  },
  methods: {
    clickIcon() {
      if (this.insNo) {
        this.$router.push({
          path: '/other/warningCenter',
          query: {
            insNo: this.insNo,
          },
        })
      } else {
        this.$router.push({
          path: '/other/warningCenter',
        })
      }
    },
    punctuationData() {
      let obj = {
        offset: 10,
        state: 0,
      }
      if (this.insNo) {
        obj['institutionNo'] = this.insNo
      }
      workOrderList(obj).then((res) => {
        if (res.code == 0) {
          this.warningIcon = res.data && res.data.length !== 0 ? false : true
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.warningIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 28px;
    height: 28px;
  }
}
</style>
