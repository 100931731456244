<template>
  <!-- 政府采购项目数据 -->
  <div class="project">
    <div class="projectData">
      <div class="projectMain">
        <div class="project-peo-money">
          <div>
            <div class="project-title">本年度公扶对象人数及发包总额</div>
            <div class="project-item">
              <div>
                <div class="project-ren">人数</div>
                <div>
                  <span class="project-num">{{
                    numberFormat(living.addNum, 'people').value
                  }}</span>
                  <span class="project-ren">{{
                    numberFormat(living.addNum, 'people').unit
                  }}</span>
                </div>
              </div>
              <div>
                <div class="project-ren">金额</div>
                <div>
                  <span class="project-num">
                    {{ numberFormat(Number(living.addAmount), 'money').value }}
                  </span>
                  <span class="project-ren">{{
                    numberFormat(Number(living.addAmount), 'money').unit
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="project-title">本年度已服务人数、次数</div>
            <div class="project-item">
              <div>
                <div class="project-ren">人数</div>
                <div>
                  <span class="project-num">{{
                    numberFormat(living.serviceNum, 'people').value
                  }}</span>
                  <span class="project-ren">{{
                    numberFormat(living.serviceNum, 'people').unit
                  }}</span>
                </div>
              </div>
              <div>
                <div class="project-ren">次数</div>
                <div>
                  <span class="project-num">{{
                    numberFormat(living.serviceTime, 'order').value
                  }}</span>
                  <span class="project-ren">{{
                    numberFormat(living.serviceTime, 'order').unit
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bing-pie">
          <div class="consumptionAmount">
            <div id="consumptionAmount" style="height: 100%; width: 100%"></div>
          </div>
          <div class="bing-to">
            <div>已消耗金额比值</div>
            <div>{{ Number(living.mealTotal).toFixed(2) }}元</div>
          </div>
        </div>
      </div>
      <div class="statisticalPie">
        <div class="statisticalPie-title">实时订单数据</div>
        <div v-if="listData && listData.length !== 0">
          <vue-seamless-scroll :data="listData" class="seamless-warp">
            <ul class="item">
              <li
                v-for="(item, index) in listData"
                :key="index"
                class="item-li"
              >
                <span class="orderNo" v-text="'编号:' + item.orderNo"></span
                ><span class="payAmount" v-text="item.payAmount + '元'"></span>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
        <div v-else class="noData">暂无数据</div>
      </div>
      <div class="seeMore" @click="seMoreFun">
        <div>查看更多</div>
        <div class="more-img">
          <img src="@/assets/images/homePage_images/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getServiceList, getSafetyLiving } from '@/api/homePage/middle.js'
import echarts from 'echarts'
export default {
  data() {
    return {
      listData: [],
      living: {
        total: 0, //总金额
        percent: '', //已消耗金额比值
        addNum: 0, //本年度新增人数
        addAmount: 0, //本年度发包总金额
        serviceNum: 0, //本年度已服务人数
        serviceTime: 0, //本年度已服务次数
      },
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  mounted() {
    this.getServiceList()
    this.getSafetyLiving()
  },
  methods: {
    getServiceList() {
      getServiceList(this.userInfo.areaCode).then((res) => {
        if (res.code == 0) {
          this.listData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getSafetyLiving() {
      getSafetyLiving(this.userInfo.areaCode).then((res) => {
        console.log('政府采购项目数据', res)
        if (res.code == 0) {
          if (this.userInfo.areaCode == 511703) {
            let data = res.data.living
            this.living = {
              total: data.total, // 总金额
              percent: ((596433 / 950000) * 100).toFixed(2) + '%', // 已消耗金额比值
              addNum: 3167, // 本年度新增人数
              addAmount: '950000', // 本年度发包总金额
              serviceNum: data.serviceNum, // 本年度已服务人数
              serviceTime: data.serviceTime, // 本年度已服务次数
              mealTotal: '596433', // 已消耗金额
            }
          } else {
            let data = res.data.living
            console.log('Living', data)
            this.living = {
              total: data.total, // 总金额
              percent: data.percent, // 已消耗金额比值
              addNum: data.addNum, // 本年度新增人数
              addAmount: data.addAmount, // 本年度发包总金额
              serviceNum: data.serviceNum, // 本年度已服务人数
              serviceTime: data.serviceTime, // 本年度已服务次数
              mealTotal: data.mealTotal, // 已消耗金额
            }
          }

          this.oldChart()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    seMoreFun() {
      this.$router.push({
        path: '/gov/govPage',
      })
    },
    numberFormat(value, type) {
      var param = {}
      var k = 10000,
        sizes =
          type == 'people'
            ? ['人', '万人', '亿人', '万亿人']
            : type == 'money'
            ? ['元', '万元', '亿元', '万亿元']
            : type == 'order'
            ? ['次', '万次', '亿次', '万亿次']
            : ['人', '万人', '亿人', '万亿人'],
        i
      if (Number(value) < k) {
        param.value = type == 'money' ? Number(value.toFixed(2)) : value
        param.unit = sizes[0]
      } else {
        i = Math.floor(Math.log(value) / Math.log(k))
        param.value = Number(value / Math.pow(k, i)).toFixed(2)
        param.unit = sizes[i]
      }
      return param
    },
    oldChart() {
      this.$nextTick(() => {
        var myChart = echarts.init(document.getElementById('consumptionAmount'))
        myChart.setOption({
          title: {
            text: this.living.percent,
            x: 'center',
            y: '50%',
            textStyle: {
              fontWeight: 'normal',
              color: '#69B1FF',
              fontSize: '14',
            },
          },
          color: ['rgba(176, 212, 251, 1)'],
          series: [
            {
              name: 'Line 1',
              type: 'pie',
              clockWise: true,
              center: ['50%', '55%'],
              radius: ['60%', '80%'],
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                  labelLine: {
                    show: false,
                  },
                },
              },
              hoverAnimation: false,
              data: [
                {
                  value: this.living.percent.split('%')[0],
                  name: '01',
                  itemStyle: {
                    normal: {
                      color: {
                        colorStops: [
                          {
                            offset: 0,
                            color: '#FF6351',
                          },
                        ],
                      },
                      label: {
                        show: false,
                      },
                      labelLine: {
                        show: false,
                      },
                    },
                  },
                },
                {
                  name: '02',
                  value: 100,
                  itemStyle: {
                    normal: {
                      color: {
                        colorStops: [
                          {
                            offset: 0,
                            color: '#2DFF99',
                          },
                        ],
                      },
                      label: {
                        show: false,
                      },
                      labelLine: {
                        show: false,
                      },
                    },
                  },
                },
              ],
            },
          ],
        })
      })
      window.addEventListener('resize', function () {
        myChart.resize()
      })
    },
  },
}
</script>
<style scoped>
.projectData {
  background: url('~@/assets/images/homePage_images/projectData.png') 0 0
    no-repeat;
  background-size: 100% 100%;
  height: 13rem;
  margin-bottom: 0.3rem;
  padding-top: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  box-sizing: border-box;
  display: flex;
  position: relative;
}

.project-title {
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #69b1ff;
  margin-bottom: 0.7rem;
}

.project-item {
  display: flex;
}
.project-item > div {
  flex: 1;
}
.projectMain {
  flex: 1.3;
  height: 100%;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  padding: 0.3rem;
  margin-right: 0.3rem;
  display: flex;
  box-sizing: border-box;
}
.statisticalPie {
  flex: 1;
  height: 100%;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  padding: 0.3rem;
  box-sizing: border-box;
}
.project-peo-money {
  display: flex;
  flex-direction: column;
  flex: 1.6;
}
.project-peo-money > div {
  flex: 1;
}

.project-peo-money > div:first-child {
  border-bottom: 1px solid #132049;
  margin-bottom: 0.6rem;
}
.project-ren {
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  margin-bottom: 0.3rem;
}
.project-num {
  font-size: 1rem;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #32cf57;
  margin-right: 0.2rem;
}

.bing-pie {
  flex: 1;
}
.consumptionAmount {
  height: 6.5rem;
}
.bing-to {
  line-height: 0.7rem;
  text-align: center;
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
}
.seamless-warp {
  height: 8.5rem;
  overflow: hidden;
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 1rem;
}
.statisticalPie-title {
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #69b1ff;
  margin-bottom: 0.3rem;
}
.item-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8rem;
  color: #2d3769;
  font-size: 0.9rem;
}

.seeMore {
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  top: 0.45rem;
  right: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.more-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.more-img img {
  width: 17px;
  height: 17px;
}
</style>
