<template>
  <div class="rightItem">
    <!-- 区域内养老机构概况 -->
    <region></region>
    <!-- 组织培训 -->
    <organization></organization>

    <div class="right-bottom-box">
      <!-- 安全 -->
      <safe></safe>
      <!-- 监控 -->
      <monitor></monitor>
    </div>
  </div>
</template>
<script>
import region from './components/region.vue'
import organization from './components/organization.vue'
import monitor from './components/monitor.vue'
import safe from './components/safe.vue'

export default {
  //注册组件
  components: {
    region,
    organization,
    monitor,
    safe,
  },
}
</script>
<style lang="scss" scoped>
.right-bottom-box {
  display: flex;
}
</style>
