<template>
  <div class="districtCounty">
    <div class="districts">
      <div class="district-header">
        <div class="county-text">
          <div class="county-title">{{ distinguish.area }}</div>
          <div class="district-time">
            <div>{{ nowDate }}</div>
            <div>{{ nowWeek }}</div>
            <div>{{ nowTime }}</div>
          </div>
        </div>
      </div>
      <div class="top-title">
        颐伦智慧康养供应链云平台政府端({{ distinguish.area }})
      </div>
      <div class="right-icon">
        <div v-auth="'screen:alarm:center'">
          <warningIcon />
        </div>
        <div class="fullScreen" @click="buttoncli">
          <img
            v-if="isFullscreen"
            src="@/assets/images/homePage_images/guanbi.png"
          />
          <img v-else src="@/assets/images/homePage_images/quanping.png" />
        </div>
        <changeAreaAndLogout />
      </div>
    </div>
    <div class="district-viewmain">
      <left ref="left"></left>
      <middle ref="middle"></middle>
      <right ref="right"></right>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import left from './left/index.vue'
import middle from './middle/index.vue'
import right from './right/index.vue'
import changeAreaAndLogout from '@/components/changeAreaAndLogout/index.vue'
import warningIcon from '@/components/warningIcon/index.vue'

export default {
  components: {
    left,
    middle,
    right,
    changeAreaAndLogout,
    warningIcon,
  },
  data() {
    return {
      //时间
      timer: null,
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      //全屏
      isFullscreen: false,
    }
  },
  computed: {
    distinguish() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  mounted() {
    //刚加载就启动计时器
    this.timer = setInterval(() => {
      this.setNowTimes()
    }, 1000)
  },
  methods: {
    // 时间
    setNowTimes() {
      let myDate = new Date()
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm =
        myDate.getMonth() + 1 < 10
          ? '0' + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1
      let dd = String(
        myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      )
      let hou = String(
        myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()
      )
      let min = String(
        myDate.getMinutes() < 10
          ? '0' + myDate.getMinutes()
          : myDate.getMinutes()
      )
      let sec = String(
        myDate.getSeconds() < 10
          ? '0' + myDate.getSeconds()
          : myDate.getSeconds()
      )
      let weeks = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min + ':' + sec
      this.nowWeek = week
    },
    // 全屏
    buttoncli() {
      // 判断浏览器是否支持全屏
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning',
        })
        return false
      }
      // 将样式改为锁屏
      if (this.isFullscreen == false) {
        //   打开全屏
        this.isFullscreen = true
        screenfull.toggle()
      } else {
        this.isFullscreen = false
        screenfull.toggle()
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  //通过路由规则，进入该组件时被调用
  beforeRouteEnter(to, from, next) {
    if (from.path == '/elderlyMessage') {
      sessionStorage.setItem('cacheOrNot', 0)
      next()
    } else {
      sessionStorage.setItem('cacheOrNot', 1)
      next()
    }
  },
}
</script>

<style scoped lang="scss">
.districtCounty {
  background: url('~@/assets/images/homePage_images/bigbackground3.png')
    no-repeat 0 0 / contain;
  background-size: 100% 100%;
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
}
.districts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: url('~@/assets/images/homePage_images/bigbackground2.png')
    no-repeat 0 0 / contain;
  background-size: 100% 100%;
  width: 100%;
  height: 3rem;
  color: #a6bafd;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin-bottom: 0.4rem;
  position: relative;
  .top-title {
    width: 50%;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    position: absolute;
    top: 0.5rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
.district-header {
  display: flex;
  align-items: center;
}
.county-text {
  color: #a6bafd;
}
.district-time {
  display: flex;
}
.district-time > div {
  margin-right: 0.4rem;
}
.county-title {
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
  font-weight: bold;
}

.right-icon {
  display: flex;
  align-items: center;
  .fullScreen {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 0.3rem;
    margin-left: 0.3rem;
  }
}

.district-viewmain {
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
  display: flex;
  padding: 0 0.5rem 0;
}
.leftItem {
  flex: 3;
}
.middleItem {
  flex: 27rem;
  margin: 0rem 0.3rem;
}
.rightItem {
  // flex: 3;
  width: 22.5rem;
}
</style>
